<template>
  <dashboard-layout>
    <div class="w-10/12 mx-auto">
      <div class="flex mb-4">
        <router-link
          class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
          :to="{
            name: 'manage-associate-access',
            params: {
              id: this.id
            }
          }"
        >
          <svg
            style="display: initial;"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Voltar
        </router-link>
      </div>
      <div class="p-4 flex">
        <h1 class="text-3xl">Novo Acesso do Associado "{{ associateName }}"</h1>
      </div>
      <form autocomplete="off">
        <form-wizard
          @on-complete="onComplete"
          :start-index="0"
          color="#69CA5E"
          title=""
          subtitle=""
          ref="form"
          next-button-text="Próximo"
          back-button-text="Anterior"
          finish-button-text="Finalizar"
        >
          <tab-content title="Identificação" icon="ti-user">
            <div
              class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
            >
              <div class="flex w-full mb-5">
                <div class="flex w-4/12 flex-col mr-5">
                  <label class="mb-2" for="name">Nome</label>
                  <input
                    v-model="$v.name.$model"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="name"
                    type="text"
                    placeholder="Nome"
                    autocomplete="off"
                  />
                  <div v-if="$v.name.$error">
                    <div class="error" v-if="!$v.name.required">
                      Campo obrigatório.
                    </div>
                    <div class="error" v-else-if="!$v.name.maxLength">
                      O nome do usuário deve ter no máximo
                      {{ $v.name.$params.maxLength.max }} caracteres.
                    </div>
                  </div>
                </div>

                <div class="flex w-4/12 flex-col mr-5">
                  <label class="mb-2" for="email">E-mail de acesso</label>
                  <input
                    v-model="$v.email.$model"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="email"
                    type="text"
                    placeholder="E-mail de acesso"
                    autocomplete="off"
                  />
                  <div v-if="$v.email.$error">
                    <div class="error" v-if="!$v.email.required">
                      Campo obrigatório.
                    </div>
                    <div class="error" v-else-if="!$v.email.maxLength">
                      O e-mail de acesso deve ter no máximo
                      {{ $v.email.$params.maxLength.max }} caracteres.
                    </div>
                  </div>
                </div>

                <div class="flex w-4/12 flex-col mr-5">
                  <label class="mb-2" for="status">Status</label>
                  <multiselect
                    id="status"
                    v-model="$v.status.selected.$model"
                    track-by="label"
                    label="label"
                    select-label="Selecionar"
                    selected-label="Selecionado"
                    deselect-label="Remover"
                    :searchable="false"
                    placeholder="Selecione um tipo"
                    :options="status.options"
                  >
                    <template slot="singleLabel" slot-scope="{ option }">
                      {{ option.label }}
                    </template>
                    <span slot="noOptions">Nenhum registro encontrado.</span>
                  </multiselect>
                  <div v-if="$v.status.selected.$error">
                    <div class="error" v-if="!$v.status.selected.required">
                      Campo obrigatório.
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full mb-5">
                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="password">Senha</label>
                  <input
                    v-model="$v.password.$model"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="password"
                    type="password"
                    placeholder="Senha"
                  />
                  <div v-if="$v.password.$error">
                    <div class="error" v-if="!$v.password.required">
                      Campo obrigatório.
                    </div>
                    <div class="error" v-else-if="!$v.password.minLength">
                      A senha deve ter no mínimo
                      {{ $v.password.$params.minLength.min }} caracteres.
                    </div>
                  </div>
                </div>

                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="password_confirmation"
                    >Confirmar Senha</label
                  >
                  <input
                    v-model="$v.password_confirmation.$model"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="password_confirmation"
                    type="password"
                    placeholder="Confirmar Senha"
                  />
                  <div v-if="$v.password_confirmation.$error">
                    <div
                      class="error"
                      v-if="!$v.password_confirmation.required"
                    >
                      Campo obrigatório.
                    </div>
                    <div
                      class="error"
                      v-else-if="!$v.password_confirmation.minLength"
                    >
                      A senha deve ter no mínimo
                      {{ $v.password_confirmation.$params.minLength.min }}
                      caracteres.
                    </div>
                    <div
                      class="error"
                      v-else-if="!$v.password_confirmation.sameAsPassword"
                    >
                      As senhas devem ser idênticas.
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full mb-5">
                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="grant-access-at"
                    >Início do Acesso</label
                  >
                  <input
                    v-model="grant_access_at"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grant-access-at"
                    type="text"
                    placeholder="Início do Acesso"
                    v-mask="'##/##/####'"
                  />
                </div>

                <div class="flex w-6/12 flex-col mr-5">
                  <label class="mb-2" for="revoke-access-at"
                    >Fim do Acesso</label
                  >
                  <input
                    v-model="revoke_access_at"
                    class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="revoke-access-at"
                    type="text"
                    placeholder="Fim do Acesso"
                    v-mask="'##/##/####'"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </tab-content>
        </form-wizard>
      </form>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import { mask } from 'vue-the-mask';
import {
  required,
  maxLength,
  minLength,
  sameAs
} from 'vuelidate/lib/validators';
import axios from '@/utils/axios';

export default {
  name: 'CreateAssociateAccess',

  title() {
    return `${process.env.VUE_APP_NAME} | Criar Acesso para o Associado`;
  },

  components: {
    DashboardLayout
  },

  directives: { mask },

  props: {
    id: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      associateName: '',
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      grant_access_at: '',
      revoke_access_at: '',
      status: {
        options: [
          { label: 'Ativo', value: 1 },
          { label: 'Inativo', value: 0 }
        ],
        selected: null
      },
      selected_status: null
    };
  },

  validations: {
    name: {
      required,
      maxLength: maxLength(100)
    },
    email: {
      required,
      maxLength: maxLength(100)
    },
    password: {
      required,
      minLength: minLength(8)
    },
    password_confirmation: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('password')
    },
    status: {
      selected: {
        required
      }
    }
  },

  methods: {
    onComplete() {
      if (this.$v.$invalid) {
        this.$v.name.$touch();
        this.$v.email.$touch();
        this.$v.password.$touch();
        this.$v.password_confirmation.$touch();
        this.$v.status.selected.$touch();
      } else {
        const userAttributes = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          status: this.status.selected.value,
          grant_access_at: this.grant_access_at,
          revoke_access_at: this.revoke_access_at
        };

        axios
          .post(`/api/associados/${this.id}/usuarios`, userAttributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            this.$router.push({
              name: 'manage-associate-access',
              params: { id: this.id }
            });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    fetchAssociate() {
      axios
        .get(`/api/associados/${this.id}`)
        .then(({ data }) => (this.associateName = data.name));
    }
  },

  created() {
    this.fetchAssociate();
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
